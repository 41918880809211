import React, { useState } from 'react';
import './Sidebar.css';

const Sidebar = ({ onFileUpload, onPageSizeChange, defaultPageSize, dimensions, onDownload }) => {
    const [selectedPageSize, setSelectedPageSize] = useState(defaultPageSize);

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            onFileUpload(file);
        }
    };

    const handlePageSizeChange = (event) => {
        const selectedSize = event.target.value;
        setSelectedPageSize(selectedSize);
        onPageSizeChange(selectedSize);
    };

    const hiddenFileInput = React.useRef(null);

    const handleClick = event => {
        hiddenFileInput.current.click();
    };

    const margin = 0.5; // Margin is always 5 inches
    const safeAreaWidth = dimensions.width - margin * 2;
    const safeAreaHeight = dimensions.height - margin * 2;

    return (
        <div className="sidebar">

            <button className="Documents-btn" onClick={handleClick}>
                <span class="folderContainer">
                    <svg
                        class="fileBack"
                        width="146"
                        height="113"
                        viewBox="0 0 146 113"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M0 4C0 1.79086 1.79086 0 4 0H50.3802C51.8285 0 53.2056 0.627965 54.1553 1.72142L64.3303 13.4371C65.2799 14.5306 66.657 15.1585 68.1053 15.1585H141.509C143.718 15.1585 145.509 16.9494 145.509 19.1585V109C145.509 111.209 143.718 113 141.509 113H3.99999C1.79085 113 0 111.209 0 109V4Z"
                            fill="url(#paint0_linear_117_4)"
                        ></path>
                        <defs>
                            <linearGradient
                                id="paint0_linear_117_4"
                                x1="0"
                                y1="0"
                                x2="72.93"
                                y2="95.4804"
                                gradientUnits="userSpaceOnUse"
                            >
                                <stop stop-color="gold"></stop>
                                <stop offset="1" stop-color="goldenrod"></stop>
                            </linearGradient>
                        </defs>
                    </svg>
                    <svg
                        class="filePage"
                        width="88"
                        height="99"
                        viewBox="0 0 88 99"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <rect width="88" height="99" fill="url(#paint0_linear_117_6)"></rect>
                        <defs>
                            <linearGradient
                                id="paint0_linear_117_6"
                                x1="0"
                                y1="0"
                                x2="81"
                                y2="160.5"
                                gradientUnits="userSpaceOnUse"
                            >
                                <stop stop-color="white"></stop>
                                <stop offset="1" stop-color="#686868"></stop>
                            </linearGradient>
                        </defs>
                    </svg>

                    <svg
                        class="fileFront"
                        width="160"
                        height="79"
                        viewBox="0 0 160 79"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M0.29306 12.2478C0.133905 9.38186 2.41499 6.97059 5.28537 6.97059H30.419H58.1902C59.5751 6.97059 60.9288 6.55982 62.0802 5.79025L68.977 1.18034C70.1283 0.410771 71.482 0 72.8669 0H77H155.462C157.87 0 159.733 2.1129 159.43 4.50232L150.443 75.5023C150.19 77.5013 148.489 79 146.474 79H7.78403C5.66106 79 3.9079 77.3415 3.79019 75.2218L0.29306 12.2478Z"
                            fill="url(#paint0_linear_117_5)"
                        ></path>
                        <defs>
                            <linearGradient
                                id="paint0_linear_117_5"
                                x1="38.7619"
                                y1="8.71323"
                                x2="66.9106"
                                y2="82.8317"
                                gradientUnits="userSpaceOnUse"
                            >
                                <stop stop-color="goldenrod"></stop>
                                <stop offset="1" stop-color="gold"></stop>
                            </linearGradient>
                        </defs>
                    </svg>
                </span>
                <p class="text">Upload File</p>
            </button>
            <input
                type="file"
                ref={hiddenFileInput}
                accept=".pdf"
                onChange={handleFileChange}
                style={{ display: 'none' }} // Hide the file input
            />
            <select value={selectedPageSize} onChange={handlePageSizeChange}>

                <option value="default">Page Size</option>
                <option value="5x8">5" x 8" </option>
                <option value="5.25x8">5.25" x 8" </option>
                <option value="5.5x8.5">5.5" x 8.5" </option>
                <option value="6x9">6" x 9" </option>
                <option value="6.14x9.21">6.14" x 9.21" </option>
                <option value="7x10">7" x 10" </option>
                <option value="8x10">8" x 10" </option>
                <option value="8.5x8.5">8.5" x 8.5" </option>
                <option value="8.5x11">8.5" x 11" </option>
                <option value="8.25x6">8.25" x 6" </option>
                <option value="8.5x5.5">8.5" x 5.5" </option>
                <option value="11x8.5">11" x 8.5" </option>
            </select>

            <form class="form">
                <div class="title">Current Dimensions</div>
                <p class="input" >Width: {dimensions.width.toFixed(2)} inches</p>
                <p class="input">Height: {dimensions.height.toFixed(2)} inches</p>
                <p className="input">Margin: {margin} inches</p>
                <p className="input">Safe Area Width: {safeAreaWidth.toFixed(2)} inches</p>
                <p className="input">Safe Area Height: {safeAreaHeight.toFixed(2)} inches</p>
            </form>

            <button type="button" class="button" onClick={onDownload}>
                <span class="button__text">Download</span>
                <span class="button__icon"><svg class="svg"  data-name="Layer 2" id="bdd05811-e15d-428c-bb53-8661459f9307" viewBox="0 0 35 35" xmlns="http://www.w3.org/2000/svg"><path stroke="goldenrod" d="M17.5,22.131a1.249,1.249,0,0,1-1.25-1.25V2.187a1.25,1.25,0,0,1,2.5,0V20.881A1.25,1.25,0,0,1,17.5,22.131Z"></path><path d="M17.5,22.693a3.189,3.189,0,0,1-2.262-.936L8.487,15.006a1.249,1.249,0,0,1,1.767-1.767l6.751,6.751a.7.7,0,0,0,.99,0l6.751-6.751a1.25,1.25,0,0,1,1.768,1.767l-6.752,6.751A3.191,3.191,0,0,1,17.5,22.693Z"></path><path stroke="goldenrod" d="M31.436,34.063H3.564A3.318,3.318,0,0,1,.25,30.749V22.011a1.25,1.25,0,0,1,2.5,0v8.738a.815.815,0,0,0,.814.814H31.436a.815.815,0,0,0,.814-.814V22.011a1.25,1.25,0,1,1,2.5,0v8.738A3.318,3.318,0,0,1,31.436,34.063Z"></path></svg></span>
            </button>
        </div>
    );
};

export default Sidebar;
