import React, { useState, useEffect } from 'react';
import { getDocument } from 'pdfjs-dist';
import './PDFViewer.css'; // Import CSS file for styling
import 'pdfjs-dist/build/pdf.worker';
import { GlobalWorkerOptions } from 'pdfjs-dist';

GlobalWorkerOptions.workerSrc = `pdfjs-dist/build/pdf.worker.min.js`;

const PDFViewer = ({ pdfData, pageSize, updateDimensions }) => {
    const [pages, setPages] = useState([]);
    const [currentPageIndex, setCurrentPageIndex] = useState(0);
    const [totalPages, setTotalPages] = useState(0);
    const [dimensions, setDimensions] = useState({ width: 0, height: 0 });


    useEffect(() => {
        const renderPages = async () => {
            const loadingTask = getDocument({ data: atob(pdfData.split(',')[1]) });
            const pdf = await loadingTask.promise;
            const pagePromises = [];

            for (let i = 1; i <= pdf.numPages; i++) {
                pagePromises.push(renderPage(pdf, i, pageSize));
            }

            const renderedPages = await Promise.all(pagePromises);
            setPages(renderedPages);
            setTotalPages(pdf.numPages);
        };

        renderPages();
    }, [pdfData, pageSize]);

    const renderPage = async (pdf, pageNumber, size) => {



        const page = await pdf.getPage(pageNumber);
        const viewport = page.getViewport({ scale: 1 });
        const canvas = document.createElement('canvas');
        const context = canvas.getContext('2d');
        const { width, height } = getFixedDimensions(viewport, size);
        setDimensions({ width, height }); // Add this line here
        canvas.width = width;
        canvas.height = height;
        const scale = Math.min(width / viewport.width, height / viewport.height);
        const scaledViewport = page.getViewport({ scale });
        await page.render({ canvasContext: context, viewport: scaledViewport }).promise;



        if (pageNumber === 1) {
            updateDimensions(width / 72, height / 72);
        }

        return canvas.toDataURL('image/png');
    };



    const handleNextPage = () => {
        setCurrentPageIndex(Math.min(currentPageIndex + 2, totalPages - 1));
    };

    const handlePreviousPage = () => {
        setCurrentPageIndex(Math.max(currentPageIndex - 2, 0));
    };

    const getFixedDimensions = (viewport, size) => {
        switch (size) {
            case "5x8":
                return { width: 378, height: 594 }; 
            case "5.25x8":
                return { width: 387, height: 594 }; 
            case "5.5x8.5":
                return { width: 405, height: 621 };
            case "6x9":
                return { width: 450, height: 666 }; 
            case "6.14x9.21":
                return { width: 451, height: 673 };
            case "7x10":
                return { width: 522, height: 738 }; 
            case "8x10":
                return { width: 594, height: 738 };
            case "8.5x8.5":
                return { width: 621, height: 621 }; 
            case "8.5x11":
                return { width: 621, height: 801 }; 
            case "8.25x6":
                return { width: 603, height: 441 }; 
            case "8.5x5.5":
                return { width: 621, height: 405 }; 
            case "11x8.5":
                return { width: 801, height: 621 }; 
            default:
                return { width: viewport.width, height: viewport.height }; // Default to the natural size
        }
    };



    const renderOverlay = (width, height, size) => {

        const bleed = 9; // 10pt bleed
        const margin = 36; // 36pt margin

        return (
            <div className="overlay-container" style={{ width, height }}>
                {/* Bleed Area */}
                <div className="overlay bleed" style={{ top: -bleed, left: -bleed, width: width + (2 * bleed), height: height + (2 * bleed) }} />

                {/* Trim Area */}
                <div className="overlay trim" style={{ top: 0, left: 0, width: width, height: height }} />

                {/* Margin Area */}
                <div className="overlay margin" style={{ top: margin, left: margin, width: width - (2 * margin), height: height - (2 * margin), backgroundColor: 'rgba(105, 105, 105, 0.1)' }} />

                
            </div>
        );
    };


    return (
        <div className="pdf-viewer-container">
            <div className="pdf-page-container">
                {pages.length > 0 && (
                    <>
                        <div className="pdf-page-wrapper">

                            <img src={pages[currentPageIndex]} alt={`Page ${currentPageIndex + 1}`} className="pdf-page" />
                            {renderOverlay(dimensions.width, dimensions.height)}
                        </div>
                        {currentPageIndex + 1 < pages.length && (
                            <div className="pdf-page-wrapper">
                                <img src={pages[currentPageIndex + 1]} alt={`Page ${currentPageIndex + 2}`} className="pdf-page" />
                                {renderOverlay(dimensions.width, dimensions.height)}

                            </div>
                        )}
                    </>
                )}
            </div>
            <div className="pdf-buttons-container">
                {currentPageIndex > 0 && (
                    <button className="button-common previous-button" onClick={handlePreviousPage}>
                        <svg width="34" height="34" viewBox="0 0 74 74" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <circle cx="37" cy="37" r="35.5" stroke-width="3"></circle>
                            <path transform="scale(-1, 1) translate(-74, 0)" d="M25 35.5C24.1716 35.5 23.5 36.1716 23.5 37C23.5 37.8284 24.1716 38.5 25 38.5V35.5ZM49.0607 38.0607C49.6464 37.4749 49.6464 36.5251 49.0607 35.9393L39.5147 26.3934C38.9289 25.8076 37.9792 25.8076 37.3934 26.3934C36.8076 26.9792 36.8076 27.9289 37.3934 28.5147L45.8787 37L37.3934 45.4853C36.8076 46.0711 36.8076 47.0208 37.3934 47.6066C37.9792 48.1924 38.9289 48.1924 39.5147 47.6066L49.0607 38.0607ZM25 38.5L48 38.5V35.5L25 35.5V38.5Z" fill="black"></path>
                        </svg>

                    </button>
                )}
                {currentPageIndex + 2 < totalPages && (
                    <button className="button-common next-button" onClick={handleNextPage}>
                        <svg width="34" height="34" viewBox="0 0 74 74" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <circle cx="37" cy="37" r="35.5" stroke-width="3"></circle>
                            <path d="M25 35.5C24.1716 35.5 23.5 36.1716 23.5 37C23.5 37.8284 24.1716 38.5 25 38.5V35.5ZM49.0607 38.0607C49.6464 37.4749 49.6464 36.5251 49.0607 35.9393L39.5147 26.3934C38.9289 25.8076 37.9792 25.8076 37.3934 26.3934C36.8076 26.9792 36.8076 27.9289 37.3934 28.5147L45.8787 37L37.3934 45.4853C36.8076 46.0711 36.8076 47.0208 37.3934 47.6066C37.9792 48.1924 38.9289 48.1924 39.5147 47.6066L49.0607 38.0607ZM25 38.5L48 38.5V35.5L25 35.5V38.5Z" fill="black"></path>
                        </svg>
                    </button>
                )}
            </div>
        </div>
    );
};

export default PDFViewer;