// Header.js

import React from 'react';
import './Header.css';
import dngLogo from './dngLogo.png'; 

const Header = () => {
    return (
        <header>
            <img src={dngLogo} alt="Diarynigracia Logo" />
            <h1>Book Formatter</h1>

        </header>
    );
};

export default Header;
