import { PDFDocument } from 'pdf-lib';

export const resizeAndDownloadPDF = async (pdfData, pageSize) => {
    const existingPdfBytes = await fetch(pdfData).then(res => res.arrayBuffer());
    const pdfDoc = await PDFDocument.load(existingPdfBytes);

    const getFixedDimensions = (size) => {
        switch (size) {
            case "5x8":
                return { width: 360, height: 576 }; // 5" x 8" in points
            case "5.25x8":
                return { width: 378, height: 576 }; // 5.25" x 8" in points
            case "5.5x8.5":
                return { width: 396, height: 612 }; // 5.5" x 8.5" in points
            case "6x9":
                return { width: 432, height: 648 }; // 6" x 9" in points
            case "6.14x9.21":
                return { width: 442, height: 663 }; // 6.14" x 9.21" in points
            case "7x10":
                return { width: 504, height: 720 }; // 7" x 10" in points
            case "8x10":
                return { width: 576, height: 720 }; // 8" x 10" in points
            case "8.5x8.5":
                return { width: 612, height: 612 }; // 8.5" x 8.5" in points
            case "8.25x11":
                return { width: 594, height: 792 }; // 8.25" x 11" in points
            case "8.5x14":
                return { width: 612, height: 1008 }; // 8.5" x 14" in points
            case "8.5x5.5":
                return { width: 612, height: 396 }; // 8.5" x 5.5" in points
            case "11x8.5":
                return { width: 792, height: 612 }; // 11" x 8.5" in points
            default:
                return null;
        }
    };

    const dimensions = getFixedDimensions(pageSize);
    if (dimensions) {
        const pages = pdfDoc.getPages();
        pages.forEach(page => {
            page.setSize(dimensions.width, dimensions.height);
        });
    }

    const pdfBytes = await pdfDoc.save();
    const blob = new Blob([pdfBytes], { type: 'application/pdf' });
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = 'modified_document.pdf';
    link.click();
};
