import React, { useState } from 'react';
import Header from './Header';
import Sidebar from './Sidebar';
import PDFViewer from './PDFViewer';
import './App.css';
import { resizeAndDownloadPDF } from './pdfUtils'; // Import the utility function

const App = () => {
    const [pdfFile, setPdfFile] = useState(null);
    const [pageSize, setPageSize] = useState('default');
    const [dimensions, setDimensions] = useState({ width: 0, height: 0 });

    const handleFileUpload = (file) => {
        if (file && file.type === 'application/pdf') {
            const reader = new FileReader();
            reader.onload = () => {
                setPdfFile(reader.result);
            };
            reader.readAsDataURL(file);
        } else {
            console.error('Invalid file:', file);
        }
    };

    const handlePageSizeChange = (selectedSize) => {
        setPageSize(selectedSize);
    };

    const updateDimensions = (width, height) => {
        setDimensions({ width, height });
    };

    const handleDownload = () => {
        resizeAndDownloadPDF(pdfFile, pageSize); // Call the utility function
    };

    return (
        <div className="app">
            <Header />
            <main>
                <Sidebar
                    onFileUpload={handleFileUpload}
                    onPageSizeChange={handlePageSizeChange}
                    defaultPageSize={pageSize}
                    dimensions={dimensions}
                    onDownload={handleDownload}
                />
                <div className="content">
                    {pdfFile ? (
                        <PDFViewer pdfData={pdfFile} pageSize={pageSize} updateDimensions={updateDimensions} />
                    ) : (
                            <><div class="loader">
                                <div class="book-wrapper">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="white"
                                        viewBox="0 0 126 75"
                                        class="book"
                                    >
                                        <rect
                                            stroke-width="5"
                                            stroke="#d0f0c0"
                                            rx="7.5"
                                            height="70"
                                            width="121"
                                            y="2.5"
                                            x="2.5"
                                        ></rect>
                                        <line
                                            stroke-width="5"
                                            stroke="#d0f0c0"
                                            y2="75"
                                            x2="63.5"
                                            x1="63.5"
                                        ></line>
                                        <path
                                            stroke-linecap="round"
                                            stroke-width="4"
                                            stroke="#0c2d17"
                                            d="M25 20H50"
                                        ></path>
                                        <path
                                            stroke-linecap="round"
                                            stroke-width="4"
                                            stroke="#0c2d17"
                                            d="M101 20H76"
                                        ></path>
                                        <path
                                            stroke-linecap="round"
                                            stroke-width="4"
                                            stroke="#0c2d17"
                                            d="M16 30L50 30"
                                        ></path>
                                        <path
                                            stroke-linecap="round"
                                            stroke-width="4"
                                            stroke="#0c2d17"
                                            d="M110 30L76 30"
                                        ></path>
                                    </svg>

                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="#ffffff74"
                                        viewBox="0 0 65 75"
                                        class="book-page"
                                    >
                                        <path
                                            stroke-linecap="round"
                                            stroke-width="4"
                                            stroke="#0c2d17"
                                            d="M40 20H15"
                                        ></path>
                                        <path
                                            stroke-linecap="round"
                                            stroke-width="4"
                                            stroke="#0c2d17"
                                            d="M49 30L15 30"
                                        ></path>
                                        <path
                                            stroke-width="5"
                                            stroke="#d0f0c0"
                                            d="M2.5 2.5H55C59.1421 2.5 62.5 5.85786 62.5 10V65C62.5 69.1421 59.1421 72.5 55 72.5H2.5V2.5Z"
                                        ></path>
                                    </svg>
                                </div>
                                <div>
                                    <p className="loaderText" >Please Upload PDF to Start</p>
                                </div>

                            

                                </div></>
                    )}
                </div>
            </main>
        </div>
    );
};

export default App;
